@use '_traits' as t;
@use 'sass:color';

.button {
  display: inline-block;
  height: auto;
  margin: 1em auto;
  padding: 0.666em 1.25em;
  font-size: 0.95rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  color: white;
  border: 2px solid transparent;
  background-color: t.$color-primary;
  cursor: pointer;
  transition: all 200ms ease;

  &:focus, &:hover {
    background-color: color.adjust(t.$color-primary, $lightness: -5%);
  }

  &:active {
    background-color: color.adjust(t.$color-primary, $lightness: -10%);
  }

  &.block {
    display: block;
  }

  &.button-secondary {
    background-color: t.$color-secondary;

    &:focus, &:hover {
      background-color: color.adjust(t.$color-secondary, $lightness: -5%);
    }

    &:active {
      background-color: color.adjust(t.$color-secondary, $lightness: -10%);
    }
  }

  &.button-small {
    padding: 0.5rem 1rem;
  }

  &.button-mini {
    font-size: 0.75rem;
    padding: 0.125rem 0.5rem;
  }

  [data-react-class="Icon"] {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: baseline;
    line-height: 1em;
    position: relative;
    top: -1px;
  }

  span + [data-react-class="Icon"] {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}

.outline-button {
  background-color: transparent;
  color: t.$color-dark;
  border: 2px solid t.$color-medium-grey;

  &:focus, &:hover, &:active {
    background-color: inherit;
  }

  &:focus, &:hover {
    opacity: 0.75;
  }

  &.button-light {
    border-color: white;
    color: white;
  }

  &.transparent {
    border-color: transparent;
  }
}
