
.app-sponsors {
  text-align: center;

  .container {
    padding-top: 0;
  }

  .sponsor-logo {
    display: inline-block;
    padding: 1rem;
  }

  .sponsor-image {
    display: block;
    height: 2.5rem;
  }
}
