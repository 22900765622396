@use '_traits' as t;

h1, h2, h3, h4, h5 {
  font-weight: bold;
  line-height: 1.25em;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 0;
  }
}

h1 { font-size: 1.75rem; }
h2 { font-size: 1.5rem; }
h3 { font-size: 1.333rem; }
h4 { font-size: 1.25rem; }
h5 { font-size: 1.125rem; }

@media (max-width: t.$breakpoint-small) {
  h1 { font-size: 1.5rem; }
  h2 { font-size: 1.25rem; }
  h3 { font-size: 1.125rem; }
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}

a {
  color: t.$color-primary;
  word-wrap: break-word;
  cursor: pointer;
}

strong {
  font-weight: 700;
}

ul, ol {
  li {
    display: list-item;
    list-style-position: inside;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

ul li {
  list-style-type: disc;
}

ol li {
  list-style-type: decimal;
}
