
// Colours
$color-primary:           #f57222 !default;
$color-primary-light:     #ff8534 !default;
$color-secondary:         #16b7c7 !default;
$color-secondary-light:   #51cedc !default;
$color-primary-dark:      #b75519 !default;
$color-secondary-dark:    #108995 !default;
$color-tertiary:          #cfdd28 !default;
$color-dark:              #222222 !default;
$color-dark-grey:         #888888 !default;
$color-medium-grey:       #a1a1a1 !default;
$color-light-grey:        #dbdbdb !default;
$color-pale-grey:         #e0e0e0 !default;
$color-light:             #ffffff !default;
$color-notice:            #fdf6e7 !default;
$color-danger:            #ed2b2a !default;
$color-success:           #cfdd28 !default;

// Breakpoints
$breakpoint-extra-small:  32rem !default;
$breakpoint-small:        46em !default;
$breakpoint-medium:       60em !default;
$breakpoint-large:        96rem !default;
$breakpoint-extra-large:  128rem !default;
