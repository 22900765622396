@use '_traits' as t;

.flash-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.flash {
  text-align: center;
  animation: flash 350ms 1 forwards;

  .alert {
    position: relative;
    background: t.$color-medium-grey;
    color: white;
    font-weight: bold;
    padding: 1.125rem 1.5rem;
    margin-bottom: 1.5rem;

    &[onclick='this.remove()'] {
      padding-right: 3rem;

      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }

      &:after {
        content: '\00D7';
        font-family: monospace;
        position: absolute;
        top: 50%;
        right: 0.5em;
        margin-top: -0.55em;
        font-size: 2rem;
        width: 1em;
        height: 1em;
        line-height: 1em;
        text-align: center;
      }
    }
  }

  .alert-error {
    background: t.$color-danger;
  }

  .alert-success {
    background: t.$color-success;
    color: t.$color-dark;
  }
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  } 50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
}
