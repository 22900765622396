@use '_traits' as t;
@use 'button' as b;

%input {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.25em 0.5em;
  background-color: white;
  border: thin solid t.$color-light-grey;
  border-radius: 0.25em;

  &:focus {
    border-color: t.$color-tertiary;
    box-shadow: inset 1px 0 2px rgba(0,0,0,.1),
                0 1px 5px rgba(t.$color-tertiary, 0.5);
  }
}

.form {

/* Make disabled fields gray */
  input[disabled], textarea[disabled] {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
    border-color: #ccc;
  }

  text-align: left;

  fieldset {
    display: block;
    padding: 0;
    width: 100%;
    border: 0;
    margin: 2em 0;
    border-bottom: thin solid t.$color-light-grey;
    padding-bottom: 1rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    &:last-of-type {
      margin-bottom: 1rem;
      padding-bottom: 0;
      border-bottom-width: 0;
    }

    header {
      line-height: inherit;
      border-bottom: thin solid;
      padding-bottom: 0.25em;
      margin-bottom: 0.5em;
    }

    legend {
      display: block;
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 0.75rem;
      letter-spacing: 0.025rem;
    }

    + .actions {
      margin-top: 0;
    }
  }

  label {
    display: block;
    margin: 1rem 0 0.25rem;
    text-align: left;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  input[type=text], input[type=email],
  input[type=password], input[type=search],
  input[type=color], input[type=tel],
  input[type=date], input[type=number], textarea {
    @extend %input;
    appearance: none;
  }

  select {
    @extend %input;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: inline-block;
    width: auto;
    border: 0;
    padding: 0;
    margin-right: 0.25rem;
    box-shadow: none;

    + label {
      display: inline-block;
      vertical-align: baseline;
      margin: 0;
      opacity: 0.75
    }

    &:checked + label {
      opacity: 1;
    }
  }

  textarea {
    height: auto;
    min-height: 5em;
    max-height: 24em;
  }

  .field {
    margin: 1rem 0;

    > label, .field_with_errors > label {
      margin-top: 0;
    }
  }

  .field_with_errors {
    font-weight: bold;

    label {
      color: red;
    }

    input, textarea, select, + div input {
      border-color: t.$color-danger;
    }
  }

  .checkbox-field {
    input {
      display: none;

      &:checked + label:before {
        border-color: t.$color-dark;
      }

      &:checked + label:after {
        border-color: t.$color-primary;
      }

      &:disabled + label {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    label {
      position: relative;
      padding-left: 2.25rem;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid t.$color-medium-grey;
        transform: translateY(-50%);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0.6rem;
        width: 0.5rem;
        height: 1.2rem;
        border-bottom: 3px solid transparent;
        border-right: 3px solid transparent;
        transform: rotate(45deg) translate(-120%, -50%);
      }
    }
  }

  button[type='submit'], input[type='submit'] {
    @extend .button;
  }

  .note {
    padding: 1rem;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    text-align: center;
    background-color: t.$color-pale-grey;
  }

  .actions {
    display: block;
    clear: both;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: thin solid t.$color-light-grey;
    text-align: right;
    vertical-align: top;

    .button, button[type='submit'], input[type='submit'] {
      margin-left: 0.75rem;
    }

    &.block {
      margin: 0;
      border: 0;
      padding: 0;

      button[type='submit'], input[type='submit'] {
        display: block;
        width: 100%;
        margin: 1rem 0;
      }
    }
  }
}

#error_explanation, .error-box {
  background: t.$color-danger;
  color: white;
  border-radius: 0.125rem;
  padding: 1rem;
  margin-bottom: 2rem;

  h2, p:first-child {
    font-size: 0.75rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 2rem;

    &:before {
      content: '\26A0';
      position: absolute;
      top: 0;
      left: 0;
      font-family: monospace;
      font-size: 2rem;
    }
  }

  ul {
    padding-left: 0.5rem;
  }
}

@media (min-width: t.$breakpoint-small) {
  .field-wrapper {
    display: flex;
    margin: 1rem 0;

    .field {
      flex: 1;
      margin: 0;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      &.narrow {
        flex: 0.75;
      }

      &.half {
        flex: 0.5;
      }
    }
  }
}
