@use '_traits' as t;

.pagination {
  text-align: center;
  padding: 1.5rem;
  margin: auto;

  span {
    display: inline-block;
    vertical-align: baseline;
    padding: 0 0.25rem;

    + .results-count {
      margin-top: 0.75rem;
    }
  }

  .gap {
    color: t.$color-light-grey;
  }

  .results-count {
    color: t.$color-medium-grey;
    font-size: 0.9rem;
  }
}
