.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper {
  z-index: 99;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__header select {
  background: white;
  border: thin solid #ccc;
  height: 2rem;
}

.react-datepicker__navigation {
  top: 10px;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker__month-dropdown-container {
  margin: 0 1px;
}

.react-datepicker__year-dropdown-container {
  margin: 0 1px;
}

.react-datepicker__input-container:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0.666rem;
  border: 5px solid transparent;
  border-top: 6px solid black;
  margin-top: -2.5px;
  opacity: 0.6;
  pointer-events: none;
}

.react-datepicker__input-container.react-datepicker__tether-enabled:before,
.react-datepicker__input-container:hover:before {
  opacity: 1;
}

.ReactModal__Content {
  overflow: visible !important;
}
