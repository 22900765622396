@use '_traits' as t;

:root:root {
  line-height: 1.45em;
  color: t.$color-dark;
  background-color: t.$color-light-grey;
}

:root, html, button, input, select, table, textarea {
  font-size: 100%;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

.logo {
  display: block;
  max-width: 100%;
  max-height: 6rem;
  margin: 0 auto 2rem;
}

.container {
  position: relative;
  max-width: 72rem;
  margin: auto;
  padding: 1rem;

  &.narrow {
    max-width: 32rem;
  }

  @media (min-width: t.$breakpoint-small) {
    padding: 2rem;
  }
}

.panel {
  background: white;
  padding: 1.5rem;

  @media (min-width: t.$breakpoint-small) {
    padding: 3rem;
  }
}

@media (min-width: t.$breakpoint-small) {
  .left { float: left; }
  .right { float: right; }
}
